import { useQuery } from "react-query";

import { fetchTermsData } from "../../api";
import LoadingSpinner from "../shared/LoadingSpinner";
import "./PrivacyPolicy.scss";
// import { AiOutlineSwapLeft } from "react-icons/ai";

const PrivacyPolicy = () => {
  const { data, isLoading } = useQuery("terms", fetchTermsData);

  const terms = data?.data?.data.terms;

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <section className="PrivacyPolicy">
      <div className="container">
        <h2 className="PrivacyPolicy_header text-center"> سياسة الخصوصية </h2>

        <div dangerouslySetInnerHTML={{ __html: terms }}></div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
