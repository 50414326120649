import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { HiArrowNarrowDown } from "react-icons/hi";
import { FaFacebookF, FaTwitter, FaInstagram, FaGithub, FaLinkedinIn } from "react-icons/fa";

import LoadingSpinner from "../shared/LoadingSpinner";
import { fetchLecturer } from "../../api";
import "./profile.scss";
import Course from "../courses/Course";

const Profile = () => {
  const { id } = useParams();
  const { data, isLoading } = useQuery(["lecturer", id], () =>
    fetchLecturer(id)
  );


  const lecturer = data?.data?.data;
  console.log(lecturer);

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <div className="instructor-profile mt-5 pt-5">
      <div className="container">
        <div className="row gx-4">
          <div className="col-md-8 instructor-profile__info mb-5">
            <h2 className="  instructor-profile__name ">{lecturer.name}</h2>
            <p className=" instructor-profile__position">{lecturer.position} </p>
            <div className="instructor-profile__social">
              {lecturer.facebook_link ? <a href={lecturer.facebook_link}>
                <FaFacebookF className="icon" />
              </a> : " "}
              {lecturer.twitter_link ? <a href={lecturer.twitter_link}>
                <FaTwitter className="icon" />
              </a> : " "}
              {lecturer.instagram_link ? <a href={lecturer.instagram_link}>
                <FaInstagram className="icon" />
              </a> : " "}
              {lecturer.github_link ? <a href={lecturer.github_link}>
                <FaGithub className="icon" />
              </a> : " "}
              {lecturer.linkedin_link ? <a href={lecturer.linkedin_link}>
                <FaLinkedinIn className="icon" />
              </a> : " "}
            </div>
            <div className="instructor-profile__bio">
              <p className="">{lecturer.bio}</p>
            </div>
          </div>
          <div className="col-md-4 ">
            <div className="img-container">
              {lecturer.photo ? (<>
                <img
                  src={lecturer.photo}
                  alt={lecturer.name}
                />
                <a href='#courses'>
                  <button ><HiArrowNarrowDown className="img-container__icon" /> courses   </button>
                </a>
              </>) : (
                // TODO: replace with elegant loading image
                <div className="spinner-grow" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          </div>
        </div>

      </div>
      <div id="courses" className="container  courses">
        <div className="courses__header">
          <h2>Courses</h2>
        </div>
        <div className="row ">
          {lecturer?.courses.map(course => (
            <div
              dir="rtl"
              className=" col-md-6 col-xl-4"
              key={course.id}
            >
              <Course course={course} isInstructor={true} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Profile;
