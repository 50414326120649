import { openNotification } from "../components/shared/Notification";

export function genericErrorHandler(error) {
  const statusCode = error.response ? error.response.status : null;
  if (!statusCode) {
    openNotification({
      message: "عفوا",
      description: "مشكلة فى الاتصال ، برجاء التأكد من الانترنت لديك",
    });
  } else {
    if (
      statusCode === 401 &&
      error.response.data.message !==
      "The provided credentials are incorrect." &&
      error.response.data.message !==
      "The provided Old Password is incorrect." &&
      error.config.url !== "/me"
    ) {
      openNotification({
        message: "عفوا",
        description: "غير مصرح لك ، برجاء تسجيل الدخول لمشاهدة تفاصيل الكورس . ",
      });
    } else if (statusCode > 499) {
      openNotification({
        message: "عفوا",
        description:
          "مشكلة فى سيرفرتنا! جارى العمل عليها ، برجاء المحاولة مرة اخرى",
      });
    }
    return error;
  }
}
