import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AUTH_TOKEN, getLocalStoredData, getSessionStorageData } from "../../helper";

const NonAuthRoute = ({ children, redirectTo = "/login" }) => {
  const isLogedIn = useSelector(state => {
    return state.auth.isLogedIn;
  });
  const sessionStorageData = getSessionStorageData(AUTH_TOKEN);
  const localStoredData = getLocalStoredData(AUTH_TOKEN);

  if (!isLogedIn && !sessionStorageData && !localStoredData) {
    return <Navigate to={redirectTo} replace />;
  }

  return children;
};

export default NonAuthRoute;
