import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthRoute = ({ children, redirectTo = "/" }) => {
  const isLogedIn = useSelector(state => {
    return state.auth.isLogedIn;
  });

  if (isLogedIn) {
    return <Navigate to={redirectTo} replace />;
  }

  return children;
};

export default AuthRoute;
