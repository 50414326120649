import axios from "../axois-client";

export const fetchMyCourses = () => {
  return axios.get(`/my-courses`);
};

export const openVideo = id => {
  return axios.post(`/watch-video`, { _method: "PUT", video_id: id });
};

export const answerQuestion = id => {
  return axios.post(`/answer-question`, { _method: "POST", answer_id: id });
};

export const openCourse = id => {
  return axios.post(`/open-course`, { _method: "PUT", course_id: id });
};

export const fetchVideo = id => {
  return axios.get(`/videos/${id}`);
};
