import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import { Button, Form, Container } from "react-bootstrap";
import { useMutation } from "react-query";

import { openNotification } from "../shared/Notification";
import { validateEmail, HAS_REGISTER } from "./../../helper";
import { registerApi } from "./../../api";
import "./registeration.scss";

const Register = () => {
  const registerFormRef = useRef();
  const activateUrlInputRef = useRef();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(registerApi, {
    onSuccess: data => {
      activateUrlInputRef.current.value =
        process.env.REACT_APP_BACKEND_URL + data?.data?.activate_link;
      emailjs.sendForm(
        "service_2kslb2v",
        "template_jphvpj7",
        registerFormRef.current,
        "bEC_M3cldUQT7cAFb"
      );
      navigate("/login", { state: HAS_REGISTER });
    },
    onError: () => {
      openNotification({
        title: "بيانات غير صحيحة",
        message: "برجاء التاكد من البيانات المدخلة",
      });
    },
  });

  async function registerUser(e) {
    e.preventDefault();

    const registerFromData = new FormData(registerFormRef.current);
    const registerFromObj = Object.fromEntries(registerFromData);

    if (!validateEmail(registerFromObj.email))
      return openNotification({
        message: "البريد الإلكتروني غير صحيح",
        description: "برجاء ادخال البريد الإلكتروني صحيح",
      });

    if (registerFromObj.password.length < 6)
      return openNotification({
        title: "كلمة المرور قصيرة",
        message: "برجاء ادخال كلمة مرور مكونة من 6 احرف فأكثر",
      });

    if (registerFromObj.password !== registerFromObj.password_confirmation)
      return openNotification({
        title: "تاكيد كلمة المرور غير صحيحة",
        message: "برجاء ادخال كلمة السر وتاكيد كلمة السر مطابقتان",
      });

    mutate(registerFromObj);
  }

  return (
    <div className="container">
      <section className="register-page">
        <Container className="col-md-9 col-lg-5 mx-auto mt-4 ">
          {/* 2024 */}
          <Form className="pb-4" onSubmit={registerUser} ref={registerFormRef}>
            <h2 className="login-title py-4">إنشاء حساب</h2>
            <Form.Group
              className="mb-3 f-l_names"
              controlId="formBasicUsername"
            >
              {/* 2024 */}
              <div>
                <Form.Label> الأسم الأول</Form.Label>
                <Form.Control
                  name="first_name"
                  type="text"
                  placeholder="اسم المستخدم"
                />
              </div>
              <div>
                <Form.Label> الأسم الأخير</Form.Label>
                <Form.Control
                  name="last_name"
                  type="text"
                  placeholder="اسم المستخدم"
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>البريد الإلكتروني</Form.Label>
              <Form.Control
                name="email"
                type="email"
                placeholder="البريد الإلكتروني"
              />
            </Form.Group>
            <div className="f-l_names">
              <div>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>كلمة المرور</Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="كلمة المرور"
                  />
                </Form.Group>
              </div>
              <div>
                <Form.Group className="mb-3" controlId="formBasicConfPassword">
                  <Form.Label>تأكيد كلمة المرور</Form.Label>
                  <Form.Control
                    name="password_confirmation"
                    type="password"
                    placeholder="تأكيد كلمة المرور"
                  />
                </Form.Group>
              </div>
            </div>

            <Form.Group className="mb-3" controlId="formBasicGender">
              <Form.Label>النوع</Form.Label>
              <Form.Select
                name="gender"
                aria-label="gender"
                className="w-25 shadow-none"
              >
                <option value="male">ذكر</option>
                <option value="female">أنثى</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDOB">
              <Form.Label>تاريخ الميلاد</Form.Label>
              <Form.Control
                name="date"
                type="date"
                placeholder="تاريخ الميلاد"
              />
            </Form.Group>

            <Form.Group
              className="mb-3 d-none"
              controlId="formBasicActivateLink"
            >
              <Form.Control
                ref={activateUrlInputRef}
                name="activate_link"
                type="url"
              />
            </Form.Group>
            <Form.Group className="button pt-3 pb-2">
              <Button
                type="submit"
                className="w-100 shadow-none"
                disabled={isLoading}
              >
                انشاء حساب
              </Button>
            </Form.Group>
          </Form>

          {/* <Form
            action={`${process.env.REACT_APP_BASE_URL}/login/google`}
            method="get"
          >
            <Form.Group className="google pb-2">
              <Button
                variant="danger"
                className="w-100 shadow-none"
                type="submit"
              >
                تسجيل بحساب Google
              </Button>
            </Form.Group>
          </Form>
          <Form
            action={`${process.env.REACT_APP_BASE_URL}/login/facebook`}
            method="get"
            className="pb-2"
          >
            <Form.Group className="facebook pb-3">
              <Button
                variant="primary"
                className="w-100 shadow-none"
                type="submit"
              >
                تسجيل بحساب Facebook
              </Button>
            </Form.Group>
            <p className="text-center my-3">
              لديك حساب؟ <Link to="/login">تسجيل الدخول</Link>
            </p>
          </Form> */}
        </Container>
      </section>
    </div>
  );
};

export default Register;
