import { useState } from "react";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import "./course.scss";
const Course = ({ course, isInstructor }) => {

  const [modal, setModal] = useState(false);

  const handleCourseHours = (courseHours = '00:00:00') => {
    let word, time;

    const timeParts = String(courseHours).split(":");
    const hours = parseInt(timeParts[0], 10) || 0;
    const minutes = parseInt(timeParts[1], 10) || 0;
    const seconds = parseInt(timeParts[2], 10) || 0;

    if (hours > 0) {
      word = 'ساعة';
      time = `${hours}${minutes > 0 ? `:${minutes.toString().padStart(2, '0')}` : ''}`;
    } else if (minutes > 0) {
      word = 'دقيقة';
      time = `${minutes}${seconds > 0 ? `:${seconds.toString().padStart(2, '0')}` : ''}`;
    } else {
      word = 'ثانية';
      time = seconds;
    }

    return `${time} ${word}`;
  };

  return (
    <div className="course mx-1">
      <div className={`card course__card  my-2 ${Number(course.is_free) ? "free__course" : ""}`} >
        <Link to={`/courses/${course.id}`}>
          <img
            src={`${process.env.REACT_APP_BASE_URL}/${course.course_cover_path}`}
            className=" course__img  "
            alt={course.title}
          />
        </Link>
        <div className="course__body">
          <Link to={`/courses/${course.id}`}>
            <h5 className="card-title course__title">{course.title}</h5>
            <div className="course__time">
              <span>
                <i className="fa-regular fa-clock  "></i>
                {handleCourseHours(course?.course_hours)}
              </span>
              <span>
                <i className="fa-solid fa-circle dot"></i>
                {course?.videos_count} درس
              </span>
            </div>
            <p className="course__text ">{course.description}</p>
          </Link>

          <Link
            to={`/lecturer/${course.lecturer?.id}`}
            className="text-dark m-0"
          >
            {isInstructor ? " " : <div className="instructor d-flex align-items-center my-4">
              <div className="img">
                <img className="  course__lecturer__image" src={course.lecturer?.photo} alt={course.title} />
              </div>
              <p>
                {course.lecturer?.name}
              </p>
            </div>}

          </Link>
          <div className="buttons d-flex justify-content-between">
            <button className="preview-course" onClick={() => setModal(true)}>
              مقدمة
            </button>
            <Link to={`/courses/${course.id}`} className="details-link">
              تفاصيل الكورس
            </Link>
            <Modal
              show={modal}
              onHide={() => setModal(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              contentClassName="p-0 border-none bg-transparent"
            >
              <Modal.Body className="p-0" style={{ height: "430px" }}>
                <video width="100%" height="430" controls autoPlay>
                  <track default kind="captions" src=""></track>
                  <source
                    src={`${process.env.REACT_APP_BASE_URL}/${course.intro_path}`}
                    type={`video/${course.intro_path.split(".")[1]}`}
                  ></source>
                </video>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
