export const getSessionStorageData = key => {
  try {
    const data = sessionStorage.getItem(key);

    if (data === null) {
      return undefined;
    }
    return data;
  } catch (err) {
    return undefined;
  }
};

export const setSessionStorageData = (key, value) => {
  try {
    const data = JSON.sessionStorage(value);
    sessionStorage.setItem(key, data);

    return true;
  } catch (e) {
    return undefined;
  }
};
