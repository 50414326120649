import axios from "../axois-client";
export const loginWithFacebook = data => {
  return axios.post(`/login/facebook`, data);
};

export const loginWithGoogle = data => {
  return axios.post(`/login/google`, data);
};

export const loginApi = data => {
  return axios.post(`/login`, data);
};

export const registerApi = data => {
  return axios.post(`/register`, data);
};

export const fetchCurrentUser = () => {
  return axios.post(`/me`);
};
