import { useState, useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { Button, Form, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setIsLogedIn, setToken, setUser } from "../../redux";
import { HAS_REGISTER, AUTH_TOKEN, CURRENT_USER } from "./../../helper";
import { openNotification } from "../shared/Notification";
import "./registeration.scss";
import { loginApi, loginWithFacebook, loginWithGoogle } from "./../../api";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import googleIcon from "../../assets/icons/google-icon.svg";
import facebookIcon from "../../assets/icons/facebook-icon.svg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remmemberMe, setRemmemberMe] = useState(false);
  const message = useLocation().state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginFormRef = useRef();

  useEffect(() => {
    if (message === HAS_REGISTER) {
      openNotification({
        message: "تم انشاء حساب",
        description: "برجاء ادخال البريد الالكترونى وكلمة المرور من اجل الدخول",
        isNotError: true,
      });
    }
  }, [message]);

  const authAction = (user, token) => {
    dispatch(setUser(user));
    dispatch(setToken(token));
    dispatch(setIsLogedIn(true));
  };

  const setLocalstorage = (currentUser, token) => {
    localStorage.setItem(AUTH_TOKEN, token);
    localStorage.setItem(CURRENT_USER, JSON.stringify(currentUser));
    authAction(currentUser, token);
  };

  // const setSession = (currentUser, token) => {
  //   sessionStorage.setItem(AUTH_TOKEN, token);
  //   sessionStorage.setItem(CURRENT_USER, JSON.stringify(currentUser));
  //   authAction(currentUser, token);
  // };

  const logInWithEmailAndPassword = async accessToken => {
    setLocalstorage(null, accessToken);
    // if (remmemberMe) {
    //   setLocalstorage(null, accessToken);
    // } else {
    //   setSession(null, accessToken);
    // }
    navigate("/");
  };

  const { mutate, isLoading } = useMutation(loginApi, {
    onSuccess: ({ data }) => logInWithEmailAndPassword(data.access_token),
    onError: () => {
      openNotification({
        title: "الحساب او كلمة المرور خطأ",
        message: "برجاء ادخال البريد الالكترونى وكلمة المرور صحيحين",
      });
    },
  });

  const { mutate: mutateFacebook } = useMutation(loginWithFacebook, {
    onSuccess: ({ data }) => logInWithEmailAndPassword(data.access_token),
    onError: () => {
      openNotification({
        title: "الحساب خطأ",
        message: "برجاء التأكد من حساب الفيس بوك",
      });
    },
  });
  const { mutate: mutateGoogle } = useMutation(loginWithGoogle, {
    onSuccess: ({ data }) => logInWithEmailAndPassword(data.access_token),
    onError: () => {
      openNotification({
        title: "الحساب خطأ",
        message: "برجاء التأكد من حساب جوجل",
      });
    },
  });

  return (
    <div className="container">
      <section className="login-page row">
        <Container className="col-md-9 col-lg-5 mx-auto mt-4">
          {/* 2024 */}
          <Form ref={loginFormRef} className="pb-3">
            <h2 className="login-title pb-4 pt-3">تسجيل الدخول</h2>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>البريد الإلكتروني</Form.Label>
              <Form.Control
                type="email"
                placeholder="البريد الإلكتروني"
                required
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                }}
                style={{ backgroundColor: "#fff8f8" }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>كلمة المرور</Form.Label>
              <Form.Control
                type="password"
                placeholder="كلمة المرور"
                required
                value={password}
                onChange={e => {
                  setPassword(e.target.value);
                }}
                style={{ backgroundColor: "#fff8f8" }}
              />
            </Form.Group>

            <div className="check-container">
              <Form.Group className="d-flex">
                <Form.Check
                  className="checkbox-pointer"
                  type="checkbox"
                  id="remember"
                  value={remmemberMe}
                  onChange={e => setRemmemberMe(e.target.value)}
                />
                <Form.Label
                  style={{ cursor: "pointer" }}
                  className="px-2"
                  htmlFor="remember"
                >
                  تذكرني
                </Form.Label>
              </Form.Group>
              <Link to="/forget-password" className="forget">
                <button
                  className="resset_password_button"
                  type="button"
                  disabled={!email}
                >
                  هل نسيت كلمة المرور؟
                </button>
              </Link>
            </div>

            <div className="w-100">
              <Form.Group className="button mt-3 mb-2">
                <Button
                  disabled={isLoading || !email || !password}
                  className="w-100 shadow-none"
                  onClick={() =>
                    mutate({
                      email,
                      password,
                    })
                  }
                >
                  {isLoading ? "جارى التحميل" : "تسجيل الدخول"}
                </Button>
              </Form.Group>
            </div>
            {/* 2024 */}
            <div className="social-media-login mb-2">
              <Form>
                <Form.Group className="google">
                  <LoginSocialGoogle
                    isOnlyGetToken={true}
                    client_id={process.env.REACT_APP_GG_APP_ID || ""}
                    onResolve={({ data }) =>
                      mutateGoogle({ token: data.access_token })
                    }
                  >
                    <Button
                      // variant="danger"
                      className=" shadow-none social-btns"
                      type="button"
                    >
                      <img src={googleIcon} alt="google" className="w-100" />
                      {/* تسجيل بحساب Google */}
                    </Button>
                  </LoginSocialGoogle>
                </Form.Group>
              </Form>
              <Form className="">
                <Form.Group className="facebook">
                  <LoginSocialFacebook
                    isOnlyGetToken={true}
                    appId={process.env.REACT_APP_FB_APP_ID || ""}
                    onResolve={({ data }) =>
                      mutateFacebook({ token: data.accessToken })
                    }
                  >
                    <Button
                      variant="primary"
                      className="shadow-none social-btns"
                      type="button"
                    >
                      <img src={facebookIcon} alt="fb" className="w-100" />
                      {/* تسجيل بحساب Facebook */}
                    </Button>
                  </LoginSocialFacebook>
                </Form.Group>
              </Form>
            </div>
          </Form>
          <p className="text-center my-3">
            {/* 2024 */}
            ليس لديك حساب؟{" "}
            <Link to="/register" className="register-text">
              إنشاء حساب جديد
            </Link>
          </p>
        </Container>
      </section>
    </div>
  );
};

export default Login;
